import { Route } from '@angular/router';
import { PortalLayoutComponent } from './layout/layouts/portal-layout/portal-layout.component';
import { EmptyLayoutComponent } from './layout/layouts/empty-layout/empty-layout.component';
import { authGuard } from '@twist/core';
import { OrganizationTypes } from '@twist/backbone-api-services/ciam';
import { NoAccessPageComponent } from './pages/no-access-page/no-access-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { OrganizationPermissions } from '@twist/backbone-api-services/authentication';

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: EmptyLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('@twist/authentication/feature-authentication').then((home) => home.AUTHENTICATION_ROUTES)
      },
      {
        path: 'routines',
        canActivate: [authGuard()],
        loadChildren: () => import('@twist/feature-routine-editor').then((m) => m.ROUTINE_ROUTES)
      },
      {
        path: 'installation-security',
        loadChildren: () =>
          import('@twist/feature-installation-security').then((m) => m.INSTALLATION_SECURITY_STANDALONE_ROUTES)
      }
    ]
  },
  {
    path: '',
    component: PortalLayoutComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [authGuard()],
        loadChildren: () => import('@twist/feature-dashboard').then((home) => home.DASHBOARD_ROUTES)
      },
      {
        path: 'installations',
        canActivate: [authGuard()],
        loadChildren: () => import('@twist/feature-installations-list').then((m) => m.INSTALLATION_LIST_ROUTES)
      },
      {
        path: 'products',
        canActivate: [authGuard()],
        loadChildren: () => import('@twist/feature-products').then((m) => m.PRODUCTS_ROUTES)
      },
      {
        path: 'product-blueprints',
        canActivate: [authGuard({ orgPermissions: [OrganizationPermissions.MyOrganization__ProductBlueprints_Read] })],
        loadChildren: () => import('@twist/feature-product-blueprints').then((m) => m.PRODUCT_BLUEPRINT_ROUTES)
      },
      {
        path: 'my-organization',
        canActivate: [authGuard()],
        loadChildren: () => import('@twist/feature-my-organization').then((m) => m.MY_ORGANIZATION_ROUTES)
      },
      {
        path: 'manufacturing',
        canActivate: [authGuard({ orgTypes: [OrganizationTypes.TwistAdmin] })],
        loadChildren: () => import('@twist/feature-manufacturing').then((m) => m.MANUFACTURING_ROUTES)
      },
      {
        path: 'hardware-buildup',
        canActivate: [authGuard({ orgTypes: [OrganizationTypes.TwistAdmin] })],
        loadChildren: () => import('@twist/feature-hardware-buildup').then((m) => m.HARDWARE_BUILDUP_ROUTES)
      },
      /*      {
              path: 'organizations',
              canActivate: [authGuard({ orgTypes: [OrganizationTypes.TwistAdmin] })],
              loadChildren: () => import('@twist/feature-organization-management').then((m) => m.ORGANIZATION_MANAGEMENT_ROUTES)
            },*/
      {
        path: 'profile',
        canActivate: [authGuard()],
        loadChildren: () =>
          import('@twist/profile/feature-profile').then((m) => m.PROFILE_ROUTES)
      },
      {
        path: 'admin',
        canActivate: [authGuard({ orgTypes: [OrganizationTypes.TwistAdmin] })],
        loadChildren: () =>
          import('@twist/feature-admin').then((m) => m.ADMIN_ROUTES)
      }
    ]
  }, {
    path: '',
    children: [
      {
        path: 'errors/403',
        component: NoAccessPageComponent
      }
      ,
      {
        path: '**',
        component: NotFoundPageComponent
      }
    ]
  }
];
