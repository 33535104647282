import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { RouterLink } from '@angular/router';
import { NgScrollbar } from 'ngx-scrollbar';
import { LayoutStore } from '@twist/ui-core';
import { SideNavComponent, SideNavConfig, SvgIcons, TwistLogoComponent } from '@twist/ui/components';
import { OrganizationTypes } from '@twist/backbone-api-services/ciam';
import { AuthStore, OrgPermissions } from '@twist/core';

@Component({
  standalone: true,
  selector: 'app-sidebar-nav',
  imports: [CommonModule, FaIconComponent, RouterLink, NgScrollbar, TwistLogoComponent, SideNavComponent],
  templateUrl: './sidebar-nav.component.html',
})
export class SidebarNavComponent {

  #layoutStore = inject(LayoutStore);
  private _authStore = inject(AuthStore);

  protected readonly faClose = faClose;

  toggleSidebar() {
    this.#layoutStore.toggleSidebar()
  }

  protected readonly SvgIcons = SvgIcons;

  sideNavConfig: SideNavConfig = {
    sections: [
      {
        title: 'My Twist',
        items: [
          {
            title: 'Dashboard',
            icon: SvgIcons.dashboard,
            routerLink: '/dashboard'
          },
          {
            title: 'Installations',
            icon: SvgIcons.installations,
            routerLink: '/installations'
          },
          {
            title: 'Organization',
            icon: SvgIcons.rssi,
            securityConstraints: {
              requireOrganization: true,
              orgPermissions: [
                OrgPermissions.Any,
                OrgPermissions.MyOrganization__Installations_Read]
            },
            subItems: [
              {
                title: 'Settings',
                routerLink: '/my-organization/settings'
              },
              {
                title: 'Members',
                routerLink: '/my-organization/members'
              },
              {
                title: 'Api keys',
                routerLink: '/my-organization/api-keys'
              },
              {
                title: 'Notifications',
                routerLink: '/my-organization/notifications'
              }
            ]
          }
        ]
      },
      {
        title: 'Admin',
        securityConstraints: { orgTypes: [OrganizationTypes.TwistAdmin], orgPermissions: [OrgPermissions.Any] },
        items: [
          {
            title: 'Manufacturing hub',
            icon: SvgIcons.gearsDatabase,
            securityConstraints: { orgTypes: [OrganizationTypes.TwistAdmin] },
            subItems: [
              {
                title: 'Devices',
                routerLink: '/manufacturing'
              },
              {
                title: 'Components',
                routerLink: '/manufacturing/components'
              },
              {
                title: 'Manufacturing settings',
                routerLink: '/manufacturing/settings'
              }
            ]
          }
          ,
          {
            title: 'Definitions',
            icon: SvgIcons.gearsDatabase,
            securityConstraints: { orgTypes: [OrganizationTypes.TwistAdmin] },
            subItems: [
              {
                title: 'Device types',
                routerLink: '/hardware-buildup/devices'
              },
              {
                title: 'Firmware',
                routerLink: '/hardware-buildup/firmware'
              },
              {
                title: 'Product blueprints',
                routerLink: '/hardware-buildup/product-blueprints'
              },
              {
                title: 'Routine blueprints',
                routerLink: '/hardware-buildup/routine-blueprints'
              }
            ]
          },
          {
            title: 'Security',
            icon: SvgIcons.gearsDatabase,
            securityConstraints: { orgTypes: [OrganizationTypes.TwistAdmin] },
            subItems: [
              {
                title: 'Organizations',
                routerLink: '/organizations'
              },
              {
                title: 'Uses',
                routerLink: '/user'
              }
            ]
          }
        ]
      }
    ]
  };
}
