import { patchState, signalStore, type, withMethods, withState } from '@ngrx/signals';
import { addEntity, setEntity, withEntities } from '@ngrx/signals/entities';
import {
  AiChatBackboneService,
  ChatHistoryEntry
} from '@twist/backbone-api-services/ai-assistant';
import { inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { makeUniqueId } from '@twist/utils';
import { AuthStore } from '@twist/core';

export type AiAssistantStoreState = {
  waiting: boolean;
  chatbotWindowVisible: boolean
}

const initialState: AiAssistantStoreState = {
  waiting: false,
  chatbotWindowVisible: false
};

export const AiAssistantStore = signalStore(
  { providedIn: 'root' },
  withState<AiAssistantStoreState>(initialState),
  withEntities({ entity: type<ChatHistoryEntry>(), collection: 'chatHistory' }),
  withMethods((state) => {

    const service = inject(AiChatBackboneService);
    const authStore = inject(AuthStore)

    const toggleChatbotWindow = () => {
      patchState(state, { chatbotWindowVisible: !state.chatbotWindowVisible() });
    };

    const sendMessage = (userMessage: string): void => {
      const messageId = makeUniqueId(15);
      patchState(state, addEntity({
        id: messageId,
        userMessage: userMessage,
        assistantAnswer: null
      } as ChatHistoryEntry, { collection: 'chatHistory' }));
      patchState(state, {waiting: true})
      firstValueFrom(service.handleChatMessage({
        userName: authStore?.user()?.name ?? '',
        history: state.chatHistoryEntities(),
        userMessage,
        messageId
      })).then((res) => {
        if (res.result) {
          console.log("UPDATING entity", res.result)
          patchState(state, setEntity(res.result, { collection: 'chatHistory' }));

          patchState(state, {waiting: false})
        }
      });
    };

    return {
      toggleChatbotWindow,
      sendMessage
    };
  })
);

