<div class="row justify-content-center mt-5">
  <div class="col-lg-4">
    <div class="text-center">
      <ui-twist-logo height="3em"></ui-twist-logo>

      <h1 class="text-error mt-4">403</h1>
      <h4 class="text-uppercase text-danger mt-3">No access</h4>
      <p class="text-muted mt-3">It's looking like you may have taken a wrong turn. Don't worry... it
        happens to the best of us. Here's a
        little tip that might help you get back on track.</p>

      <a class="btn btn-primary mt-3" href="/"><i class="mdi mdi-reply"></i> Return Home</a>
    </div> <!-- end /.text-center-->
  </div> <!-- end col-->
</div>
