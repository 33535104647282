<!-- ========== Left Sidebar Start ========== -->
<div class="leftside-menu sidebar-enable">
  <a routerLink="/" class="logo">
    <span class="logo-lg">
      <ui-twist-logo variant="full"></ui-twist-logo>
    </span>
    <span class="logo-sm">
      <ui-twist-logo variant="compact"></ui-twist-logo>
    </span>
  </a>

  <!-- Sidebar Hover Menu Toggle Button -->
  <div class="button-sm-hover" data-bs-toggle="tooltip" data-bs-placement="right" title="Show Full Sidebar">
    <i class="ri-checkbox-blank-circle-line align-middle"></i>
  </div>

  <!-- Full Sidebar Menu Close Button -->
  <div class="button-close-fullsidebar" (click)="toggleSidebar()">
    <fa-icon [icon]="faClose" class="align-middle"></fa-icon>
  </div>

  <!-- Sidebar -left -->
  <nav class="h-100" id="leftside-menu-container">
    <ng-scrollbar>

      <ui-side-nav [config]="sideNavConfig"></ui-side-nav>

        <!-- Help Box -->
        <div class="help-box text-white text-center">
          <a href="javascript: void(0);" class="float-end close-btn text-white">
            <i class="mdi mdi-close"></i>
          </a>
          <ui-twist-logo variant="compact" height="40"></ui-twist-logo>
          <h4 class="mt-3">Need help?</h4>
          <p class="mb-3">Consult our extensive documentation and training material.</p>
          <a href="javascript: void(0);" class="btn btn-primary btn-sm">Twist help center</a>
        </div>
        <!-- end Help Box -->


    </ng-scrollbar>
    <div class="clearfix"></div>
  </nav>
</div>
