<!-- ========== Topbar Start ========== -->
<div class="navbar-custom">
  <div class="topbar container-fluid">
    <div class="d-flex align-items-center gap-lg-2 gap-1">

      <!-- Topbar Brand Logo -->
      <div class="logo-topbar">
        <!-- Logo light -->
        <a href="index.html" class="logo-light">
                    <span class="logo-lg">
                      <ui-twist-logo variant="compact"></ui-twist-logo>
                    </span>
          <span class="logo-sm">
                      <ui-twist-logo variant="compact"></ui-twist-logo>
                    </span>
        </a>

        <!-- Logo Dark -->
        <a href="index.html" class="logo-dark">
                    <span class="logo-lg">
                      <ui-twist-logo variant="compact"></ui-twist-logo>
                    </span>
          <span class="logo-sm">
                      <ui-twist-logo variant="compact"></ui-twist-logo>
                    </span>
        </a>
      </div>

      <!-- Sidebar Menu Toggle Button -->
      <button class="button-toggle-menu" (click)="toggleSidebar()">
        <fa-icon [icon]="faBars" [fixedWidth]="true"></fa-icon>
      </button>


      <!-- Topbar Search Form -->
      <div class="app-search dropdown d-none d-lg-block">
        <form>
          <div class="input-group">
            <input type="search" class="form-control dropdown-toggle" placeholder="Search..." id="top-search">

            <button class="input-group-text btn btn-primary" type="submit">
              <fa-icon [icon]="faSearch" [fixedWidth]="true"></fa-icon>
              <span>Search</span></button>
          </div>
        </form>

        <!--
                <div class="dropdown-menu dropdown-menu-animated dropdown-lg" id="search-dropdown">
                  &lt;!&ndash; item&ndash;&gt;
                  <div class="dropdown-header noti-title">
                    <h5 class="text-overflow mb-2">Found <span class="text-danger">17</span> results</h5>
                  </div>

                  &lt;!&ndash; item&ndash;&gt;
                  <a href="javascript:void(0);" class="dropdown-item notify-item">
                    <i class="uil-notes font-16 me-1"></i>
                    <span>Analytics Report</span>
                  </a>

                  &lt;!&ndash; item&ndash;&gt;
                  <a href="javascript:void(0);" class="dropdown-item notify-item">
                    <i class="uil-life-ring font-16 me-1"></i>
                    <span>How can I help you?</span>
                  </a>

                  &lt;!&ndash; item&ndash;&gt;
                  <a href="javascript:void(0);" class="dropdown-item notify-item">
                    <i class="uil-cog font-16 me-1"></i>
                    <span>User profile settings</span>
                  </a>

                  &lt;!&ndash; item&ndash;&gt;
                  <div class="dropdown-header noti-title">
                    <h6 class="text-overflow mb-2 text-uppercase">Users</h6>
                  </div>

                  <div class="notification-list">
                    &lt;!&ndash; item&ndash;&gt;
                    <a href="javascript:void(0);" class="dropdown-item notify-item">
                      <div class="d-flex">
                        <img class="d-flex me-2 rounded-circle" src="assets/images/users/avatar-2.jpg"
                             alt="Generic placeholder image" height="32">
                        <div class="w-100">
                          <h5 class="m-0 font-14">Erwin Brown</h5>
                          <span class="font-12 mb-0">UI Designer</span>
                        </div>
                      </div>
                    </a>

                    &lt;!&ndash; item&ndash;&gt;
                    <a href="javascript:void(0);" class="dropdown-item notify-item">
                      <div class="d-flex">
                        <img class="d-flex me-2 rounded-circle" src="assets/images/users/avatar-5.jpg"
                             alt="Generic placeholder image" height="32">
                        <div class="w-100">
                          <h5 class="m-0 font-14">Jacob Deo</h5>
                          <span class="font-12 mb-0">Developer</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
        -->
      </div>
    </div>

    <ul class="topbar-menu d-flex align-items-center gap-3">
<!--      <li class="dropdown d-lg-none">
        <a class="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="#" role="button"
           aria-haspopup="false" aria-expanded="false">
          <i class="ri-search-line font-22"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-animated dropdown-lg p-0">
          <form class="p-3">
            <input type="search" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
          </form>
        </div>
      </li>-->

      <li ngbDropdown class="dropdown me-3">
        <button ngbDropdownToggle class="nav-link arrow-none"
                aria-haspopup="false" aria-expanded="false">
          <img src="assets/images/flags/us.jpg" alt="user-image" class="me-0 me-sm-1" height="12">
          <span class="align-middle d-none d-lg-inline-block">English</span>
          <fa-icon [icon]="faChevronDown" class="ms-2 d-none d-sm-inline-block align-middle"></fa-icon>
        </button>
        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end dropdown-menu-animated">

          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item">
            <img src="assets/images/flags/germany.jpg" alt="user-image" class="me-1" height="12"> <span
            class="align-middle">German</span>
          </a>

          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item">
            <img src="assets/images/flags/italy.jpg" alt="user-image" class="me-1" height="12"> <span
            class="align-middle">Italian</span>
          </a>

          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item">
            <img src="assets/images/flags/spain.jpg" alt="user-image" class="me-1" height="12"> <span
            class="align-middle">Spanish</span>
          </a>

          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item">
            <img src="assets/images/flags/russia.jpg" alt="user-image" class="me-1" height="12"> <span
            class="align-middle">Russian</span>
          </a>

        </div>
      </li>
<!--
      <li ngbDropdown class="notification-list">
        <button ngbDropdownToggle class="nav-link dropdown-toggle arrow-none"
                aria-haspopup="false" aria-expanded="false">
          <fa-icon [icon]="faBell"></fa-icon>
          <span class="noti-icon-badge"></span>
        </button>
        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg py-0">
          <div class="p-2 border-top-0 border-start-0 border-end-0 border-dashed border">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0 font-16 fw-semibold"> Notification</h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="text-dark text-decoration-underline">
                  <small>Clear All</small>
                </a>
              </div>
            </div>
          </div>

          <div class="px-2" style="max-height: 300px;" data-simplebar>

            <h5 class="text-muted font-13 fw-normal mt-2">Today</h5>
            &lt;!&ndash; item&ndash;&gt;

            <a href="javascript:void(0);" class="dropdown-item p-0 notify-item card unread-noti shadow-none mb-2">
              <div class="card-body">
                <span class="float-end noti-close-btn text-muted"><i class="mdi mdi-close"></i></span>
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <div class="notify-icon bg-primary">
                      <i class="mdi mdi-comment-account-outline"></i>
                    </div>
                  </div>
                  <div class="flex-grow-1 text-truncate ms-2">
                    <h5 class="noti-item-title fw-semibold font-14">Datacorp <small class="fw-normal text-muted ms-1">1
                      min ago</small></h5>
                    <small class="noti-item-subtitle text-muted">Caleb Flakelar commented on Admin</small>
                  </div>
                </div>
              </div>
            </a>

            &lt;!&ndash; item&ndash;&gt;
            <a href="javascript:void(0);" class="dropdown-item p-0 notify-item card read-noti shadow-none mb-2">
              <div class="card-body">
                <span class="float-end noti-close-btn text-muted"><i class="mdi mdi-close"></i></span>
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <div class="notify-icon bg-info">
                      <i class="mdi mdi-account-plus"></i>
                    </div>
                  </div>
                  <div class="flex-grow-1 text-truncate ms-2">
                    <h5 class="noti-item-title fw-semibold font-14">Admin <small class="fw-normal text-muted ms-1">1
                      hours ago</small></h5>
                    <small class="noti-item-subtitle text-muted">New user registered</small>
                  </div>
                </div>
              </div>
            </a>

            <h5 class="text-muted font-13 fw-normal mt-0">Yesterday</h5>

            &lt;!&ndash; item&ndash;&gt;
            <a href="javascript:void(0);" class="dropdown-item p-0 notify-item card read-noti shadow-none mb-2">
              <div class="card-body">
                <span class="float-end noti-close-btn text-muted"><i class="mdi mdi-close"></i></span>
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <div class="notify-icon">
                      <img src="assets/images/users/avatar-2.jpg" class="img-fluid rounded-circle" alt="" />
                    </div>
                  </div>
                  <div class="flex-grow-1 text-truncate ms-2">
                    <h5 class="noti-item-title fw-semibold font-14">Cristina Pride <small
                      class="fw-normal text-muted ms-1">1 day ago</small></h5>
                    <small class="noti-item-subtitle text-muted">Hi, How are you? What about our next meeting</small>
                  </div>
                </div>
              </div>
            </a>

            <h5 class="text-muted font-13 fw-normal mt-0">30 Dec 2021</h5>

            &lt;!&ndash; item&ndash;&gt;
            <a href="javascript:void(0);" class="dropdown-item p-0 notify-item card read-noti shadow-none mb-2">
              <div class="card-body">
                <span class="float-end noti-close-btn text-muted"><i class="mdi mdi-close"></i></span>
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <div class="notify-icon bg-primary">
                      <i class="mdi mdi-comment-account-outline"></i>
                    </div>
                  </div>
                  <div class="flex-grow-1 text-truncate ms-2">
                    <h5 class="noti-item-title fw-semibold font-14">Datacorp</h5>
                    <small class="noti-item-subtitle text-muted">Caleb Flakelar commented on Admin</small>
                  </div>
                </div>
              </div>
            </a>

            &lt;!&ndash; item&ndash;&gt;
            <a href="javascript:void(0);" class="dropdown-item p-0 notify-item card read-noti shadow-none mb-2">
              <div class="card-body">
                <span class="float-end noti-close-btn text-muted"><i class="mdi mdi-close"></i></span>
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <div class="notify-icon">
                      <img src="assets/images/users/avatar-4.jpg" class="img-fluid rounded-circle" alt="" />
                    </div>
                  </div>
                  <div class="flex-grow-1 text-truncate ms-2">
                    <h5 class="noti-item-title fw-semibold font-14">Karen Robinson</h5>
                    <small class="noti-item-subtitle text-muted">Wow ! this admin looks good and awesome design</small>
                  </div>
                </div>
              </div>
            </a>

            <div class="text-center">
              <i class="mdi mdi-dots-circle mdi-spin text-muted h3 mt-0"></i>
            </div>
          </div>

          &lt;!&ndash; All&ndash;&gt;
          <a href="javascript:void(0);" class="dropdown-item text-center text-primary notify-item border-top py-2">
            View All
          </a>

        </div>
      </li>-->

      <li class="d-none d-sm-inline-block me-3">
        <button class="nav-link" title="Theme Mode" (click)="toggleTheme()">
          @if (theme() === 'light') {
            <fa-icon [icon]="faMoon"></fa-icon>
          } @else {
            <fa-icon [icon]="faSun"></fa-icon>
          }
        </button>
      </li>


      <!-- <li class="d-none d-md-inline-block">
         <a class="nav-link" href="" data-toggle="fullscreen">
           <i class="ri-fullscreen-line font-22"></i>
         </a>
       </li>-->

      <!--<li ngbDropdown>
        <button class="nav-link" ngbDropdownToggle>
          <ui-avatar
            [email]="currentUser().email"
            [username]="currentUser().name"
            class="me-2" />
          <span class="text-dark">{{ currentUser().name }}</span>
        </button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem disabled>
            <i class="align-middle me-1" data-feather="user"></i>
            <span>Profile</span>
          </button>
          <button ngbDropdownItem disabled>
            <i class="align-middle me-1" data-feather="pie-chart"></i>
            <span>Notifications</span>
          </button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem disabled>
            <i class="align-middle me-1" data-feather="settings"></i>
            <span>Settings & Privacy</span>
          </button>
          <button ngbDropdownItem disabled>
            <i class="align-middle me-1" data-feather="help-circle"></i>
            <span>Help Center</span>
          </button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem (click)="logout()">Log out</button>
        </div>
      </li>-->

      <li class="dropdown" ngbDropdown *ngIf="authenticatedUser() as user">
        <button class="nav-link dropdown-toggle arrow-none nav-user px-2" ngbDropdownToggle role="button"
                aria-haspopup="false" aria-expanded="false">
                    <span class="account-user-avatar">
                        <ui-avatar
                          [email]="user.email"
                          [username]="user.name"
                          class="" />
                    </span>
          <span class="d-lg-flex flex-column gap-1 d-none">
                        <h5 class="my-0">{{ user.name }}</h5>
            @if (organization(); as org) {
              <h6 class="my-0 fw-normal">{{ org.name }}</h6>
            }

                    </span>
        </button>
        <div ngbDropdownMenu class="dropdown-menu-end dropdown-menu-animated profile-dropdown">
          <!-- item-->
          <!--          <div class=" dropdown-header noti-title">
                      <h6 class="text-overflow m-0">Welcome !</h6>
                    </div>-->
          <button ngbDropdownItem routerLink="/profile/my-account">
            <fa-icon [icon]="faUser" class="me-1"></fa-icon>
            <span>Profile</span>
          </button>
          <button ngbDropdownItem  routerLink="/profile/notifications">
            <fa-icon [icon]="faUser" class="me-1"></fa-icon>
            <span>Notifications</span>
          </button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem  routerLink="/profile/preferences">
            <fa-icon [icon]="faUser" class="me-1"></fa-icon>
            <span>Settings & Privacy</span>
          </button>
          <button ngbDropdownItem disabled>
            <fa-icon [icon]="faUser" class="me-1"></fa-icon>
            <span>Help Center</span>
          </button>
          <div class="dropdown-divider"></div>
          @if (hasMultipleOrganizations()) {
            <h6 class="dropdown-header">Switch organization:</h6>
            @for (org of userOrganizations(); track org.key) {
              @if (org.key !== organization()?.key) {
                <button ngbDropdownItem (click)="switchOrganization(org.key)">
                  <fa-icon [icon]="faShuffle" class="me-1"></fa-icon>
                  <span>{{ org.name }}</span>
                </button>
              } @else {
                <button ngbDropdownItem disabled class="text-muted">
                  <fa-icon [icon]="faCheck" class="me-1"></fa-icon>
                  <span>{{ org.name }}</span>
                </button>
              }
            }
            <div class="dropdown-divider"></div>
          }
          <button ngbDropdownItem (click)="logout()">
            <fa-icon [icon]="faUser" class="me-1"></fa-icon>
            <span>Log out</span>
          </button>
        </div>
        <!--&lt;!&ndash; item&ndash;&gt;
        <a href="javascript:void(0);" class="dropdown-item">
          <i class="mdi mdi-account-circle me-1"></i>
          <span>My Account</span>
        </a>

        &lt;!&ndash; item&ndash;&gt;
        <a href="javascript:void(0);" class="dropdown-item">
          <i class="mdi mdi-account-edit me-1"></i>
          <span>Settings</span>
        </a>

        &lt;!&ndash; item&ndash;&gt;
        <a href="javascript:void(0);" class="dropdown-item">
          <i class="mdi mdi-lifebuoy me-1"></i>
          <span>Support</span>
        </a>

        &lt;!&ndash; item&ndash;&gt;
        <a href="javascript:void(0);" class="dropdown-item">
          <i class="mdi mdi-lock-outline me-1"></i>
          <span>Lock Screen</span>
        </a>

        &lt;!&ndash; item&ndash;&gt;
        <a href="javascript:void(0);" class="dropdown-item">
          <i class="mdi mdi-logout me-1"></i>
          <span>Logout</span>
        </a>
  </div>-->
      </li>
    </ul>
  </div>
</div>
<!-- ========== Topbar End ========== -->
<!--

<nav class="navbar navbar-expand navbar-light navbar-bg">
  <a class="sidebar-toggle" (click)="toggleSidebar()">
    <i class="hamburger align-self-center"></i>
  </a>&lt;!&ndash;
  <xng-breadcrumb></xng-breadcrumb>&ndash;&gt;
  <div class="navbar-collapse collapse">
    <ul class="navbar-nav navbar-align">
      <li class="d-none d-sm-inline-block">
        <button
          class="nav-link"
          aria-label="Theme Mode"
          (click)="toggleTheme()">
          <fa-icon [icon]="faMoon"></fa-icon>
        </button>
      </li>
      <li ngbDropdown>
        <button class="nav-link" ngbDropdownToggle>
          <ui-avatar
            [email]="currentUser().email"
            [username]="currentUser().name"
            class="me-2" />
          <span class="text-dark">{{ currentUser().name }}</span>
        </button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem disabled>
            <i class="align-middle me-1" data-feather="user"></i>
            <span>Profile</span>
          </button>
          <button ngbDropdownItem disabled>
            <i class="align-middle me-1" data-feather="pie-chart"></i>
            <span>Notifications</span>
          </button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem disabled>
            <i class="align-middle me-1" data-feather="settings"></i>
            <span>Settings & Privacy</span>
          </button>
          <button ngbDropdownItem disabled>
            <i class="align-middle me-1" data-feather="help-circle"></i>
            <span>Help Center</span>
          </button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem (click)="logout()">Log out</button>
        </div>
      </li>
    </ul>
  </div>
</nav>-->
