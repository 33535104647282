import { Component, computed, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthStore } from '@twist/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import {
  faBars,
  faBell,
  faCheck,
  faChevronDown,
  faHamburger,
  faMoon,
  faSearch,
  faShuffle,
  faSun,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { LayoutStore } from '@twist/ui-core';
import { AvatarComponent, TwistLogoComponent } from '@twist/ui/components';
import { RouterLink } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-toolbar-nav',
  imports: [CommonModule, NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle, FaIconComponent, TwistLogoComponent, AvatarComponent, RouterLink],
  templateUrl: './toolbar-nav.component.html'
})
export class ToolbarNavComponent {

  private _authStore = inject(AuthStore);
  private _layoutStore = inject(LayoutStore);

  authenticatedUser = this._authStore.user;
  organization = this._authStore.organizationAuthInfo;

  userOrganizations = computed(() => this._authStore.userAuthInfo()?.organizations ?? []);

  theme = this._layoutStore.theme;

  hasMultipleOrganizations = computed(() => {
    const organizations = this.userOrganizations();
    return organizations.length > 1;
  });

  toggleSidebar() {
    this._layoutStore.toggleSidebar();
  }

  logout() {
    this._authStore.logout();
  }

  protected readonly faMoon = faMoon;

  toggleTheme() {
    this._layoutStore.toggleTheme();
  }

  switchOrganization(key: string) {
    this._authStore.switchOrganization(key);
  }

  protected readonly faHamburger = faHamburger;
  protected readonly faBars = faBars;
  protected readonly faUser = faUser;
  protected readonly faSearch = faSearch;
  protected readonly faSun = faSun;
  protected readonly faBell = faBell;
  protected readonly faChevronDown = faChevronDown;


  protected readonly faCheck = faCheck;
  protected readonly faShuffle = faShuffle;
}
