import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TwistLogoComponent } from '@twist/ui/components';

@Component({
  standalone: true,
  imports: [CommonModule, TwistLogoComponent],
  templateUrl: './no-access-page.component.html',
  styleUrl: './no-access-page.component.scss',
})
export class NoAccessPageComponent {}
